import React from "react";
import videoBg from "../assets/videoBg.mp4";
import Landing from "./Landing/Landing";
import styled from "styled-components";

const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
`;

const ContentDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`;

const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  `;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Main = () => {
  return (
    <MainDiv>
      <OverlayDiv></OverlayDiv>
      <Video  playsInLine controls={false} autoPlay loop muted>
        <source type="video/mp4" src={videoBg} />
      </Video>
      <ContentDiv>
        <Landing />
      </ContentDiv>
    </MainDiv>
  );
};

export default Main;
